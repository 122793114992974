<template>
  <div class="adien-container" ref="container"></div>
</template>

<script>
  import { onMounted, ref, computed, watch } from "vue";
import {useStore} from 'vuex'
import RequestHandler from '@apiClient/RequestHandler';
import {getCartItemMakePaymentParams} from "@/helpers/RequestParamsHelper";
import {ACTION_TYPES} from "@/constants";
  import { useRoute, useRouter } from "vue-router";
import usePrice from "@/helpers/usePrice";

const AdyenCheckout = require("@adyen/adyen-web");

export default {
  name: 'adyen-payment-container',
  setup(props, {emit}) {
    const store = useStore();
    const data = store.getters.data;
    const container = ref(null);
    const reservationFetchParams = computed(() => store.getters.reservationFetchParams);
    const cartItems = computed(() => store.getters.cartItems);
    const router = useRouter();
    const route = useRoute();
    const { getCartTotalPrice } = usePrice();
    const totalAmount = computed(() => getCartTotalPrice(cartItems, props.paymentInfo) * 100);

    const handleSubmission = async (data, component, url) => {
      try {
        const res = await RequestHandler.makePaymentRequest(url, data);
        handleServerResponse(res.data, component);
        if (res.data.reservation) {
          store.dispatch(ACTION_TYPES.storeReservationFetchParams, res.data.reservation);
        }
      } catch (error) {
        console.error(error);
        alert("Error occurred. Look at console for details");
        emit('loading', false);
      }
    }

    let dropin, checkout, configuration;

    // Handles responses sent from your server to the client
    const handleServerResponse = (res, component) => {
      if (res && res.payments && res.payments.action) {
        component.handleAction(res.payments.action);
      } else {
        const result = res && res.payments ? res.payments.resultCode : null;
        switch (result) {
          case "Authorised":
          case "Pending":
          case "Received":
            router.push({name: 'confirmation', params: route.params});
            break;
          case "Refused":
            alert('Payment Refused. Please try again.');
            if (configuration) {
              checkout.update(configuration);
            }
            break;
          default:
            alert('Payment Refused. Please try again.');
            if (configuration) {
                checkout.update(configuration);
            }
            break;
        }
      }
      emit('loading', false);
    }
    const initCheckout = async (ref) => {
      if (!ref.value) {
        return;
      }
      try {
        const {data: paymentData} = await RequestHandler.fetchPaymentMethods(data.id);
        configuration = {
          paymentMethodsResponse: paymentData,
          clientKey: paymentData.clientKey,
          locale: "en_US",
          environment: paymentData.environment,
          paymentMethodsConfiguration: {
            card: {
              billingAddressRequired: false,
            },
          },
          showPayButton: false,
          amount: {
            value: totalAmount.value,
            currency: data.currency,
          },
          onSubmit: (state, component) => {
            store.dispatch(ACTION_TYPES.syncWithStorage);
            if (state.isValid) {
              emit('loading', true);
              let vouchersParams = [];
              cartItems.value.forEach((item) => {
                vouchersParams.push(getCartItemMakePaymentParams(item, props.paymentInfo))
              })
              handleSubmission({
                    storeId: data.id,
                    purchaser: props.paymentInfo,
                    vouchers: vouchersParams,
                    payments: state.data
                  }, component,
                  '/api/adyen/payments'
              );
            }
          },
          onAdditionalDetails: (state, component) => {
            handleSubmission(
                state.data,
                component,
                '/api/adyen/payments/details?confirmationNumber=' + reservationFetchParams.value.confirmationNumber
            );
          },
        };
        checkout = new AdyenCheckout(configuration);
        dropin = checkout.create('dropin').mount(ref.value);
      } catch (error) {
        console.error(error);
        alert('Error occurred. Look at console for details');
      }
    }

    onMounted(() => {
      initCheckout(container);
    });

    watch(totalAmount, (current, prev) => {
      configuration.amount.value = current;
      checkout.update(configuration);
    });

    const paymentSubmit = () => dropin.submit();

    return {container, paymentSubmit}
  },
  props: {
    paymentInfo: {
      required: true,
      type: Object
    }
  }
}
</script>

<style src="@adyen/adyen-web/dist/adyen.css">
</style>