<template>
  <header-line v-if="!loading"></header-line>
  <div class="content" v-show="!loading" v-bind:class="{'header_wo_logo': data.hideHeaderLogo}">
    <div class="site-width" v-if="!invalidState">
      <div class="payment__page">
        <checkout-header-line step="2"></checkout-header-line>
        <div class="form">
          <div class="checkout__content">

            <div class="checkout__payment">
              <div class="form__title">
                1. {{ t('label.purchace_details') }} <svg class="icon i24x24"><use xlink:href="#i-info"></use></svg>
              </div>
              <div class="form__row form__row--columns user-info">
                <custom-select
                    custom-container-id="titlecontainer"
                    v-bind:label="t('label.title')"
                    name="title"
                    v-bind:empty-text="t('label.select_title')"
                    v-model:value="paymentInfo.title"
                    v-bind:items="data.titles"
                    v-bind:parent-class="isFieldInvalid('title') ? 'error': ''"
                    v-on:update:value="clearErrors('title')"
                ></custom-select>

                <div class="form__row form__row--column3" id="firstNamecontainer"
                     v-bind:class="{'error': isFieldInvalid('firstName')}"
                >
                  <label class="form__label" for="ffname">{{ t('label.first_name') }}</label>
                  <input class="form__input" type="text" name="fname" id="ffname"
                         v-model="paymentInfo.firstName"
                         v-on:keyup="clearErrors('firstName')"
                  >
                  <div class="error" v-if="isFieldInvalid('firstName')">
                    {{ t(getFieldError('firstName')) }}
                  </div>
                </div>
                <div class="form__row form__row--column3" id="lastNamecontainer"
                     v-bind:class="{'error': isFieldInvalid('lastName')}"
                >
                  <label class="form__label" for="flname">{{ t('label.last_name') }}</label>
                  <input class="form__input" type="text" name="lname" id="flname"
                         v-model="paymentInfo.lastName"
                         v-on:keyup="clearErrors('lastName')"
                  >
                  <div class="error" v-if="isFieldInvalid('lastName')">
                    {{ t(getFieldError('lastName')) }}
                  </div>
                </div>

                <div class="form__row form__row--column3" id="emailcontainer"
                     v-bind:class="{'error': isFieldInvalid('email')}"
                >
                  <label class="form__label" for="femail">{{ t('label.email_1_word') }}</label>
                  <input class="form__input" type="text" name="email" id="femail"
                         v-model="paymentInfo.email"
                         v-on:keyup="clearErrors('email')"
                  >
                  <div class="error" v-if="isFieldInvalid('email')">
                    {{ t(getFieldError('email')) }}
                  </div>
                </div>

                <div class="form__row form__row--column3" id="phonecontainer"
                     v-bind:class="{'error': isFieldInvalid('phone')}"
                >
                  <label class="form__label" for="fphone">{{ t('label.phone') }}</label>
                  <input class="form__input" type="text" name="phone" id="fphone"
                         v-model="paymentInfo.phone"
                         v-on:keyup="clearErrors('phone')"
                  >
                  <div class="error" v-if="isFieldInvalid('phone')">
                    {{ t(getFieldError('phone')) }}
                  </div>
                </div>
                <div class="form__row form__row--column3"></div>

                <div class="form__row form__row--column3" id="streetcontainer"
                     v-bind:class="{'error': isFieldInvalid('street')}"
                >
                  <label class="form__label" for="street">{{ t('label.recipient_street') }}</label>
                  <input class="form__input" type="text" name="street" id="street"
                         v-model="paymentInfo.street" v-on:keyup="clearErrors('street')">
                  <div class="error" v-if="isFieldInvalid('street')">
                    {{ t(getFieldError('street')) }}
                  </div>
                </div>
                <div class="form__row form__row--column3" id="housecontainer"
                     v-bind:class="{'error': isFieldInvalid('house')}"
                >
                  <label class="form__label" for="house">{{ t('label.recipient_house') }}</label>
                  <input class="form__input" type="text" name="house" id="house"
                         v-model="paymentInfo.house"
                         v-on:keyup="clearErrors('house')"
                  >
                  <div class="error" v-if="isFieldInvalid('house')">
                    {{ t(getFieldError('house')) }}
                  </div>
                </div>
                <div class="form__row form__row--column3"
                     v-bind:class="{'error': isFieldInvalid('postcode')}"
                     v-bind:id="'postcodecontainer'"
                >
                  <label class="form__label" for="postcode">{{ t('label.recipient_postcode') }}</label>
                  <input class="form__input" type="text" name="postcode" id="postcode"
                         v-model="paymentInfo.postcode"
                         v-on:keyup="clearErrors('postcode')"
                  >
                  <div class="error" v-if="isFieldInvalid('postcode')">
                    {{ t(getFieldError('postcode')) }}
                  </div>
                </div>
                <div class="form__row form__row--column3" id="citycontainer"
                     v-bind:class="{'error': isFieldInvalid('city')}"
                >
                  <label class="form__label" for="city">{{ t('label.recipient_city') }}</label>
                  <input class="form__input" type="text" name="city" id="city"
                         v-model="paymentInfo.city"
                         v-on:keyup="clearErrors('city')">
                  <div class="error" v-if="isFieldInvalid('city')">
                    {{ t(getFieldError('city')) }}
                  </div>
                </div>

                <custom-select
                  custom-container-id="countrycontainer"
                  v-bind:label="t('label.country')"
                  name="country"
                  v-bind:empty-text="t('label.select_country')"
                  v-model:value="paymentInfo.country"
                  v-bind:items="shippingCountries"
                  v-bind:parent-class="isFieldInvalid('country') ? 'error' : ''"
                  v-on:update:value="clearErrors('country')"
                ></custom-select>
              </div>

              <div class="form__separator"></div>

              <div v-if="hasRetailProduct">
                <div class="form__title">2. {{ t('label.shipping_address') }}</div>
                <div class="form__row form__row--radio form__row--columns hidden-fields">
                  <div class="form__row form__row--column2"
                       v-bind:class="{'error': isFieldInvalid('shipping')}"
                  >
                    <input class="form__input form__input--radio hide-hidden" type="radio" value="ME"
                           name="shipping" id="shipping_me"
                           v-model="paymentInfo.deliverRetailTo"
                           v-on:change="clearErrors('shipping')"
                    >
                    <label class="form__label form__label--radio" for="shipping_me">
                      {{ t('label.same_as_above') }}
                    </label>
                  </div>
                  <div class="form__row form__row--column2"
                       v-bind:class="{'error': isFieldInvalid('shipping')}"
                  >
                    <input class="form__input form__input--radio hide-hidden" type="radio" value="RECIPIENT"
                           name="shipping" id="shipping_other"
                           v-model="paymentInfo.deliverRetailTo"
                           v-on:change="clearErrors('shipping')"
                    >
                    <label class="form__label form__label--radio" for="shipping_other">
                      {{ t('label.other_address') }}
                    </label>
                  </div>
                </div>

                <div class="form__row form__row--columns user-info" v-if="paymentInfo.deliverRetailTo === 'RECIPIENT'">
                  <div class="form__row form__row--column3" id="firstNamecontainerShipping"
                       v-bind:class="{'error': isFieldInvalid('firstNameShipping')}"
                  >
                    <label class="form__label" for="ffnameShipping">{{ t('label.first_name') }}</label>
                    <input class="form__input" type="text" name="fnameShipping" id="ffnameShipping"
                           v-model="paymentInfo.firstNameShipping"
                           v-on:keyup="clearErrors('firstNameShipping')"
                    >
                    <div class="error" v-if="isFieldInvalid('firstNameShipping')">
                      {{ t(getFieldError('firstNameShipping')) }}
                    </div>
                  </div>
                  <div class="form__row form__row--column3" id="lastNamecontainerShipping"
                       v-bind:class="{'error': isFieldInvalid('lastNameShipping')}"
                  >
                    <label class="form__label" for="flnameShipping">{{ t('label.last_name') }}</label>
                    <input class="form__input" type="text" name="lnameShipping" id="flnameShipping"
                           v-model="paymentInfo.lastNameShipping"
                           v-on:keyup="clearErrors('lastNameShipping')"
                    >
                    <div class="error" v-if="isFieldInvalid('lastNameShipping')">
                      {{ t(getFieldError('lastNameShipping')) }}
                    </div>
                  </div>
                  <div class="form__row form__row--column3"></div>

                  <div class="form__row form__row--column3" id="ship_streetcontainer"
                       v-bind:class="{'error': isFieldInvalid('streetShipping')}"
                  >
                    <label class="form__label" for="streetShipping">{{ t('label.recipient_street') }}</label>
                    <input class="form__input" type="text" name="streetShipping" id="streetShipping"
                           v-model="paymentInfo.streetShipping" v-on:keyup="clearErrors('streetShipping')">
                    <div class="error" v-if="isFieldInvalid('streetShipping')">
                      {{ t(getFieldError('streetShipping')) }}
                    </div>
                  </div>
                  <div class="form__row form__row--column3" id="housecontainerShipping"
                       v-bind:class="{'error': isFieldInvalid('houseShipping')}"
                  >
                    <label class="form__label" for="houseShipping">{{ t('label.recipient_house') }}</label>
                    <input class="form__input" type="text" name="houseShipping" id="houseShipping"
                           v-model="paymentInfo.houseShipping"
                           v-on:keyup="clearErrors('houseShipping')"
                    >
                    <div class="error" v-if="isFieldInvalid('houseShipping')">
                      {{ t(getFieldError('houseShipping')) }}
                    </div>
                  </div>
                  <div class="form__row form__row--column3"
                       v-bind:class="{'error': isFieldInvalid('postcodeShipping')}"
                       v-bind:id="'postcodecontainerShipping'"
                  >
                    <label class="form__label" for="postcodeShipping">{{ t('label.recipient_postcode') }}</label>
                    <input class="form__input" type="text" name="postcodeShipping" id="postcodeShipping"
                           v-model="paymentInfo.postcodeShipping"
                           v-on:keyup="clearErrors('postcodeShipping')"
                    >
                    <div class="error" v-if="isFieldInvalid('postcodeShipping')">
                      {{ t(getFieldError('postcodeShipping')) }}
                    </div>
                  </div>
                  <div class="form__row form__row--column3" id="citycontainerShipping"
                       v-bind:class="{'error': isFieldInvalid('cityShipping')}"
                  >
                    <label class="form__label" for="cityShipping">{{ t('label.recipient_city') }}</label>
                    <input class="form__input" type="text" name="cityShipping" id="cityShipping"
                           v-model="paymentInfo.cityShipping"
                           v-on:keyup="clearErrors('cityShipping')">
                    <div class="error" v-if="isFieldInvalid('cityShipping')">
                      {{ t(getFieldError('cityShipping')) }}
                    </div>
                  </div>

                  <custom-select
                    custom-container-id="countrycontainerShipping"
                    v-bind:label="t('label.country')"
                    name="countryShipping"
                    v-bind:empty-text="t('label.select_country')"
                    v-model:value="paymentInfo.countryShipping"
                    v-bind:items="shippingCountries"
                    v-bind:parent-class="isFieldInvalid('countryShipping') ? 'error' : ''"
                    v-on:update:value="clearErrors('countryShipping')"
                  ></custom-select>
                </div>
                <div class="form__separator"></div>
              </div>

              <div class="payment__card">
                <div class="form__title">
                  {{ hasRetailProduct ? '3' : '2' }}. {{ t('label.payment_details') }}
                </div>
                <div class="form__row--error" v-if="error">
                  {{ error }}
                </div>
                <adyen-payment-container
                    ref="paymentComponent"
                    v-bind:payment-info="paymentInfo"
                    v-on:loading="loading = $event"
                    v-if="data.paymentProvider === 'adyen'"
                ></adyen-payment-container>
              </div>
            </div>

            <div class="checkout__order">
              <div class="order">
                <div class="order__title">{{ t('label.your_order') }}</div>
                <div class="order__table">
                  <table class="table" width="100%" cellspacing="0" cellpadding="0">
                    <tbody>
                    <tr class="table__row">
                      <th class="table__cell table__cell--header">{{ t('label.item') }}</th>
                      <th class="table__cell table__cell--header">{{ t('label.quantity_short') }}</th>
                      <th class="table__cell table__cell--header">{{ t('label.price') }}</th>
                    </tr>
                    <tr class="table__row" v-for="cartItem in groupedCartItems" v-bind:key="cartItem.data.voucher.id">
                      <td class="table__cell">{{ cartItem.data.voucher.name }}</td>
                      <td class="table__cell">{{ cartItem.count }}</td>
                      <td class="table__cell">{{ numberFormat(getCartItemPrice(cartItem), data.currencySymbol) }}</td>
                    </tr>
                    <tr class="table__row" v-if="getCartRetailTotalTax(cartItems) > 0">
                      <td class="table__cell text-right" colspan="2">{{ data.retailTaxDescription || t('label.total_tax') }}</td>
                      <td class="table__cell">{{ numberFormat(getCartRetailTotalTax(cartItems), data.currencySymbol) }}</td>
                    </tr>

                    <tr class="table__row" v-if="getCartGiftTotal(cartItems) > 0">
                      <td class="table__cell text-right" colspan="2">{{t('label.total_gift_price') }}</td>
                      <td class="table__cell">{{ numberFormat(getCartGiftTotal(cartItems), data.currencySymbol) }}</td>
                    </tr>
                    <tr class="table__row" v-if="getCartShippingTotal(cartItems, paymentInfo) > 0">
                      <td class="table__cell text-right" colspan="2">{{t('label.shipping_cost') }}</td>
                      <td class="table__cell">{{ numberFormat(getCartShippingTotal(cartItems, paymentInfo), data.currencySymbol) }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="order__total">
                  <span class="label">{{ t('label.total_price') }}</span>
                  {{ numberFormat(getCartTotalPrice(cartItems, paymentInfo), data.currencySymbol) }}
                </div>
                <div class="order__agree order-left">
                  <input class="form__input form__input--checkbox" type="checkbox" id="terms"
                         v-model="paymentInfo.acceptTC">
                  <label class="form__label form__label--checkbox" for="terms">
                    <svg class="icon i24x24">
                      <use xlink:href="#i-checked-input" v-if="paymentInfo.acceptTC"></use>
                      <use xlink:href="#i-check-input" v-else></use>
                    </svg>
                  </label>
                  <div class="terms-container" v-html="data.termsText"></div>
                </div>
                <div class="form__row order-left" v-if="data.subscribeForNewsletterText">
                  <input class="form__input form__input--checkbox" type="checkbox" id="emails"
                         v-model="paymentInfo.subscribeToOffers">
                  <label class="form__label form__label--checkbox" for="emails">
                    <svg class="icon i24x24">
                      <use xlink:href="#i-checked-input" v-if="paymentInfo.subscribeToOffers"></use>
                      <use xlink:href="#i-check-input" v-else></use>
                    </svg>
                    {{ data.subscribeForNewsletterText }}
                  </label>
                </div>
                <div class="order__btns">
                  <button class="btns btn-green" v-on:click="pay">
                    {{ t('button.pay_now') }}
                  </button>
                </div>
                <div class="form__row--error error-tc" v-if="!paymentInfo.acceptTC && paymentInfo.showTCError">
                  {{ t('error.accept_tc') }}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <footer-line v-if="!loading"></footer-line>
  <page-loader v-if="loading"></page-loader>
</template>

<script>
import HeaderLine from "@/components/HeaderLine";
import CheckoutHeaderLine from "@/components/CheckautHeaderLine";
import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'
import {computed, reactive, onMounted, ref, watchEffect} from 'vue'
import {useI18n} from "vue-i18n";
import FooterLine from "@/components/FooterLine";
import {ACTION_TYPES} from "@/constants";
import {numberFormat} from "@/helpers/PriceHelper";
import CustomSelect from "@/components/CustomSelect";
import {
  clear,
  isFieldInvalid,
  isFormInvalid,
  validatePaymentInfo,
  getFieldError,
  cartItemCheckoutValidate,
  scrollToErrorElement
} from "@/helpers/ValidatorHelper";
import AdyenPaymentContainer from "@/components/payment/Adyen";
import PageLoader from "@/components/PageLoader";
import { isProductRetail } from "@/helpers/ProductHelper";
import usePrice from "@/helpers/usePrice";

export default {
  name: 'payment',
  components: {PageLoader, AdyenPaymentContainer, CustomSelect, FooterLine, CheckoutHeaderLine, HeaderLine},
  setup() {
    const store = useStore();
    const groupedCartItems = computed(() => store.getters.groupedCartItems);
    const data = computed(() => store.getters.data);
    const cartItems = computed(() => store.getters.cartItems);
    const route = useRoute();
    const router = useRouter();

    const hasRetailProduct = computed(() => cartItems.value.find((item) => isProductRetail(item.voucher)));
    const shippingCountries = computed(() => {
      if (!hasRetailProduct.value) {
        return data.value.countries;
      }

      const countries = {};
      data.value.shippingCountries && data.value.shippingCountries.forEach((el) => {
        countries[el.name] = el.name;
      });

      return countries;
    });

    const {
      getCartItemPrice, getCartTotalPrice, getCartTotalTax, getCartRetailTotalTax,
      getCartGiftTotal, getCartShippingTotal
    } = usePrice();

    const paymentInfo = reactive({
      title: null,
      country: null,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      street: null,
      house: null,
      postcode: null,
      city: null,
      subscribeToOffers: false,
      acceptTC: false,
      showTCError: false,
      deliverRetailTo: 'ME',
      firstNameShipping: null,
      lastNameShipping: null,
      streetShipping: null,
      houseShipping: null,
      postcodeShipping: null,
      cityShipping: null,
      countryShipping: null,
    });

    const paymentComponent = ref(null);

    const pay = () => {
      clear();
      cartItems.value.forEach((item, key) => cartItemCheckoutValidate(item, key));
      if (isFormInvalid()) {
        router.push({name: 'checkout', params: {...route.params, with_error: 1}});
        return;
      }

      clear();

      validatePaymentInfo(paymentInfo, cartItems.value);
      store.dispatch(ACTION_TYPES.syncWithStorage);
      if (!paymentInfo.acceptTC) {
        paymentInfo.showTCError = true;
        return;
      }
      if (!isFormInvalid()) {
        paymentComponent.value.paymentSubmit();
      } else {
        scrollToErrorElement();
      }
    }

    onMounted(() => clear());

    const invalidState = ref(false);
    // check not empty cart
    watchEffect(() => {
      if (data.value && !cartItems.value.length) {
        invalidState.value = true;
        router.push({name: 'home', params: route.params});
      }
    })

    const loading = ref(0);
    const i18n = useI18n();

    return {
      t: i18n.t,
      groupedCartItems,
      cartItems,
      numberFormat,
      pay,
      paymentInfo,
      clearErrors: clear, isFieldInvalid, getFieldError,
      loading,
      invalidState,
      paymentComponent,
      error: route.query.error,
      shippingCountries, hasRetailProduct,
      getCartItemPrice, getCartTotalPrice, getCartTotalTax, getCartRetailTotalTax,
      getCartGiftTotal, getCartShippingTotal,
      data
    }
  }
}
</script>

<style>
.order-left, .order-left p {
  text-align: left;
}

.order__agree label {
  width: 30px;
  position: absolute;
  left: 24px;
  margin-top: -3px;
}

.error-tc {
  margin-top: 14px;
}

.terms-container {
  margin-left: 37px;
}
</style>